import Image from "next/image";
import Link from "next/link";
import { isMobile } from 'react-device-detect';

type CardListProp = {
    dataToShow: {id: number; src: any; redirectURL: string}[]
}

function CardList(props: CardListProp) {
    const { dataToShow = [] } = props;

    return (
        <div className="flex justify-center @sm:justify-start @md:justify-start gap-6 @sm:gap-1.5 items-center w-full @sm:overflow-auto @md:overflow-auto">
            {dataToShow?.slice(0,6)
                ?.map((item: any, index: any) => {
                    return (
                        <div
                            key={item.id}
                            className={`w-[16%] @sm:shrink-0 @sm:w-[46%] @md:shrink-0 @md:w-[32%] @sm:mb-2 border-[#D9D9D9] border-b-[10px] border-[1px] @sm:border-b-[6px] @md:border-b-[7px] rounded-md cursor-pointer`}
                        >
                            <Link href={item.redirectURL}>
                                <Image
                                    className="rounded-md aspect-[4/3.5] w-full @sm:aspect-square @md:aspect-square object-fill object-center"
                                    width={800}
                                    height={800}
                                    alt={`image-${index + 1}`}
                                    src={(isMobile && item?.srcMob) ? item?.srcMob : item?.src}
                                />
                                <div className="text-center">
                                    <h3 className="@sm:text-10 @md:text-12 h-12 overflow-hidden text-center flex justify-center items-center capitalize">
                                        {item.displayText}
                                    </h3>
                                </div>
                            </Link>
                        </div>
                    );
                })}
        </div>
    );
}

export default CardList;
