'use client';
import { getCrazyDealsProduct } from '@/store/slices/actions/home-action';
import { SetBrandAttributeSlugs } from '@/store/slices/brand-slice';
import { SetThirdCategorySlugs } from '@/store/slices/categorySlice';
import {
    setDiscountFilter,
    setHeaderFilter,
    setIsDiscountFilter,
} from '@/store/slices/filter-slice';
import { AppDispatch } from '@/store/store';
import { createEncodedURL } from '@/utils/utilities';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const CrazyDeals = ({ showLength }: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const router = useRouter();

    const crazyDealsData = useSelector(
        (state: any) => state.home_store.crazyDeals
    );

    const [isMobile, setIsMobile] = useState<undefined | boolean>(undefined);

    useEffect(() => {
        setIsMobile(window.innerWidth <= 768);
        dispatch(getCrazyDealsProduct());
    }, []);

    if (typeof isMobile !== 'boolean') return null;

    const filteredCrazyDealsData = crazyDealsData.filter(
        (item: any) => item.status
    );

    return (
        <div className="flex justify-center @sm:justify-start @md:justify-start  gap-6 @sm:gap-1.5 @sm:nowrap items-center w-full @sm:overflow-auto">
            {filteredCrazyDealsData
                ?.slice(0, showLength ? 16 : 8)
                ?.map((item: any, index: any) => {
                    const isAlternateColor =
                        Math.floor(index / (isMobile ? 3 : 4)) % 2 === 1;
                    const borderColor = isAlternateColor
                        ? 'border-b-[#6E2A61]'
                        : 'border-b-[#FF8F2F]';

                    return (
                        <div
                            onClick={(e) => {
                                dispatch(setHeaderFilter(true));
                                let arr = [
                                    item?.minDiscount,
                                    item?.maxDiscount,
                                ];
                                dispatch(setDiscountFilter(arr));
                                dispatch(setIsDiscountFilter(true));
                                dispatch(
                                    SetThirdCategorySlugs([
                                        item?.categoryId?.slug,
                                    ])
                                );
                                dispatch(
                                    SetBrandAttributeSlugs([
                                        item?.brandId?.slug,
                                    ])
                                );

                                let encodedUrl = createEncodedURL(
                                    'product-listing',
                                    {
                                        page: 1,
                                        third: item?.categoryId?.slug,
                                        discount: arr?.toString(),
                                    }
                                );

                                if (item?.brandId) {
                                    encodedUrl = createEncodedURL(
                                        'product-listing',
                                        {
                                            page: 1,
                                            brand: item?.brandId?.slug,
                                            discount: arr?.toString(),
                                        }
                                    );
                                }
                                router.push(encodedUrl);
                            }}
                            key={index}
                            className={`w-[23%] @sm:w-[46%] @md:w-[32%] @sm:mb-2 @sm:shrink-0 ${borderColor} border-[#D9D9D9] border-b-[10px] border-[1px] @sm:border-b-[6px] @md:border-b-[7px] rounded-md cursor-pointer`}
                        >
                            <Image
                                className="rounded-md aspect-[4/3.5]  w-full @sm:aspect-square @md:aspect-square object-fill object-center "
                                width={800}
                                height={800}
                                alt={`crazy-deal-${index + 1}`}
                                src={item?.bannerImage}
                            />
                            <div className="text-center my-4 @sm:my-1.5 @md:my-2 ">
                                <h2 className="@sm:text-10 @md:text-12 h-12 overflow-hidden text-center flex justify-center items-center capitalize">
                                    {item?.brandId
                                        ? item?.brandId?.value.length > 18
                                            ? item?.brandId?.value.slice(
                                                  0,
                                                  18
                                              ) + '...'
                                            : item?.brandId?.value
                                        : item?.categoryId?.name.length > 18
                                          ? item?.categoryId?.name.slice(
                                                0,
                                                18
                                            ) + '...'
                                          : item?.categoryId?.name}
                                </h2>
                                <p className="fs-18 @sm:text-sm @md:text-sm text-[#3B3B3B] mt-2 @sm:mt-1 @md:mt-1">
                                    {item?.minDiscount}% to {item?.maxDiscount}%
                                    off
                                </p>
                                {/* <p className="fs-18 @sm:text-xs @md:text-xs   text-[#3B3B3B] mt-2 @sm:mt-1 @md:mt-1">{item?.brandId ? item?.brandId?.value: item?.categoryId?.name}</p> */}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default CrazyDeals;
